import React, { forwardRef, useRef, useState } from "react";
import clsx from "clsx";

const InputComponent = forwardRef(
  ({
    filter,
    handleChange,
    value,
    isNumber,
    isText,
    index,
    clearRange,
    selectedValues,
  }) => {
    function formatTimeValue(timeStr) {
      // Extract hours, minutes, and seconds from the string
      const matches = timeStr.match(/(\d+)h (\d+)m (\d+)s/);
      if (!matches) return "";

      // Format into HH:MM or HH:MM:SS
      const hours = matches[1].padStart(2, "0");
      const minutes = matches[2].padStart(2, "0");
      const seconds = matches[3].padStart(2, "0");

      // Check if the input type="time" supports seconds
      const input = document.createElement("input");
      input.type = "time";
      input.value = "00:00:00";
      const supportsSeconds = input.value === "00:00:00";

      return supportsSeconds
        ? `${hours}:${minutes}:${seconds}`
        : `${hours}:${minutes}`;
    }

    function formatDateTimeLocalValue(dateTimeStr) {
      const date = new Date(dateTimeStr);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");

      const seconds = date.getSeconds().toString().padStart(2, "0");

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    const [hasRange, setHasRange] = useState(
      value?.value?.split("|").length > 1 ? true : false
    );

    const valueToShow = !value
      ? ""
      : filter.inputType === "time"
      ? formatTimeValue(value)
      : filter.inputType === "datetime-local"
      ? formatDateTimeLocalValue(value)
      : value || filter.defaultValue || "";

    const mainPartValue = hasRange
      ? (!!valueToShow.value && valueToShow.value.split("|")[0]) || ""
      : valueToShow.value;
    let secondPartValue = hasRange
      ? (!!valueToShow.value && valueToShow.value.split("|")[1]) || ""
      : "";

    const today = new Date();
    const formattedToday = today.toISOString().split("T")[0];

    return (
      <div key={filter.key} className=" items-center mb-3">
        <label className="font-semibold text-sm text-gray-500 inline-flex rtl:text-right w-full">
          {filter.name}
        </label>
        {isNumber ? (
          <div className="relative">
            <input
              type="number"
              name={filter.key}
              placeholder={filter.placeHolder}
              value={value?.value || ""}
              onChange={(e) => handleChange(e, filter)}
              onBlur={(e) =>
                handleChange(e, filter, isNumber, filter.decimalPoint)
              }
              className={clsx(
                `border border-gray-300 rounded-md p-2 w-full mt-2`,
                filter.prefix ? "pl-8" : "pl-4"
              )}
              onKeyDown={(e) => {
                if (!filter.hasDecimal && (e.key === "." || e.key === "e")) {
                  e.preventDefault();
                }
              }}
              min="0" // This sets the minimum value to 0
              step={filter.step}
              disabled={filter.disabled && filter.disabled(value?.value)}
              tabIndex={index}
            />
            <span className="absolute left-2 top-4">
              <span className="text-gray-500">{filter.prefix}</span>
            </span>
          </div>
        ) : isText ? (
          <div className="relative">
            <input
              type="text"
              name={filter.key}
              placeholder={filter.placeHolder}
              value={value?.value || ""}
              onChange={(e) => handleChange(e, filter)}
              className="border border-gray-300 rounded-md p-2 w-full mt-2"
              tabIndex={index}
              disabled={filter.disabled && filter.disabled(value?.value)}
            />
          </div>
        ) : (
          <div className="relative">
            <div
              className={clsx(
                `flex items-center justify-between gap-4 mt-2`,
                hasRange && "pr-[36px]"
              )}
            >
              <input
                id="dateTimeInput"
                type={filter.inputType} //datetime-local, date, time
                name={filter.key}
                placeholder={filter.placeHolder}
                value={mainPartValue}
                onChange={(e) => handleChange(e, filter)}
                className="border border-gray-300 rounded-md p-2 w-full"
                min={
                  filter.dynamicMinDate && filter.dynamicMinDate(selectedValues)
                }
                max={
                  hasRange && secondPartValue ? secondPartValue : formattedToday
                }
                onKeyDown={(e) => {
                  if (e.key === "-" || e.key === "e") {
                    e.preventDefault();
                  }
                }}
                tabIndex={index}
                step={filter.step || 1}
              />
              {!hasRange && (
                <button
                  disabled={!mainPartValue}
                  className={clsx(
                    `whitespace-nowrap`,
                    !mainPartValue && "text-gray-500 cursor-not-allowed"
                  )}
                  onClick={() => setHasRange(true)}
                >
                  Add range
                </button>
              )}
            </div>
            <div className="flex items-center justify-between gap-4 mt-2 group">
              {hasRange && (
                <input
                  id="dateTimeInputRange"
                  type={filter.inputType} //datetime-local, date, time
                  name={filter.key}
                  placeholder={filter.placeHolder}
                  value={secondPartValue || ""}
                  onChange={(e) =>
                    handleChange(e, filter, false, 0, true, valueToShow.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "-" || e.key === "e") {
                      e.preventDefault();
                    }
                  }}
                  className="border border-gray-300 rounded-md p-2 w-full mt-2"
                  min={
                    filter.dynamicMinDate
                      ? filter.dynamicMinDate(selectedValues)
                      : mainPartValue
                  }
                  disabled={!mainPartValue}
                  max={formattedToday}
                  tabIndex={index}
                  step={filter.step || 1}
                />
              )}

              {hasRange && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6 text-gray-400 cursor-pointer group-hover:text-red-500 hover:scale-125 hover:animate-pulse transition-all duration-700 ease-in-out"
                  onClick={() => {
                    handleChange(
                      { target: { value: mainPartValue, name: filter.key } },
                      filter,
                      false,
                      0,
                      false
                    );
                    setHasRange(false);
                    clearRange && clearRange();
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);

InputComponent.displayName = "InputComponent";

export default InputComponent;
