import React, { useEffect, useState } from "react";
import myStore from "./store";
import { Observer } from "mobx-react";
import CustomTable from "@components/CustomTable";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "@components/LoaderComponent/loader";

import {
  fetchDynamicFilters,
  transformData,
  resolveMismatchColumn,
  FormatWithCommas,
} from "@helpers/helpers";
import clsx from "clsx";

const RunMatchingRecords = () => {
  const location = useLocation();
  const [statistics, setStatistics] = useState({});
  const [dynamicFilters, setDynamicFilters] = useState([]);
  const [currentType, setCurrentType] = useState("");
  const { t: translate, i18n } = useTranslation();

  useEffect(() => {
    myStore.loading = true;
    const queryParams = new URLSearchParams(location.search);
    const runID = queryParams.get("run_id");
    const type = queryParams.get("type").toLocaleUpperCase();
    const streamName = queryParams.get("stream_name");
    const businessDay = queryParams.get("business_day");
    const businessDayInstance = queryParams.get("business_day_instance");
    myStore.reconciliationRunID = runID;
    myStore.matchingResultTag = type;
    setCurrentType(type);
    myStore.fillData(false, ({ stats }) => {
      setStatistics({
        streamName: streamName,
        runID: runID,
        businessDay: businessDay + " " + businessDayInstance,
        ...stats,
      });
    });
    fetchDynamicFilters("RunMatchingRecordFilters", (newFilters) => {
      setDynamicFilters(newFilters);
    });
    myStore.loading = false;
  }, []);

  const getDynamicFilters = (key, onlyChildren = false) => {
    return transformData(
      (!!dynamicFilters &&
        dynamicFilters.length > 0 &&
        dynamicFilters.find((filter) => filter.ColumnName === key)?.Values) ||
        [],
      onlyChildren
    );
  };

  const statusColors = {
    CRITICAL: "bg-red-000",
    HIGH: "bg-red-400",
    MEDIUM: "bg-orange-400",
    LOW: "bg-yellow-400",
  };
  const textColors = {
    CRITICAL: "text-red-000",
    HIGH: "text-red-400",
    MEDIUM: "text-orange-400",
    LOW: "text-yellow-400",
  };

  const pinningInitialState = ["pin"];

  const columns = [
    {
      accessorKey: "AreaName",
      header: translate("run-matching-records.columns.Area"),
      canFilter: true,
      isMultiple: true,
      sortable: true,
      size: 150,
      truncate: true,
      processFilterValue: (value, row) => {
        return row.AreaId;
      },
      processFilterKey: (value, row) => {
        return "AreaId";
      },
      filterType: "checkbox",
      operator: "EQ",
    },
    {
      accessorKey: "OutletName",
      header: translate("run-matching-records.columns.Outlet"),
      canFilter: true,
      isMultiple: true,
      sortable: true,
      size: 150,
      truncate: true,
      processFilterValue: (value, row) => {
        return row.OutletId;
      },
      processFilterKey: (value, row) => {
        return "OutletId";
      },
      filterType: "checkbox",
      operator: "EQ",
    },
    {
      accessorKey: "TransactionTerminalCode",
      header: translate("run-matching-records.columns.Terminal"),
      processFilterValue: (value, row) => {
        return row.TransactionTerminalCode;
      },
      processFilterKey: (value, row) => {
        return "TransactionTerminalCode";
      },
      size: 150,
      canFilter: true,
      sortable: true,
      filterType: "checkbox",
      operator: "EQ",
    },
    {
      accessorKey: "RecordMatchingKey",
      header: translate("run-matching-records.columns.MatchingKey"),
      size: 150,
      sortable: true,
      canPin: false,
    },
    {
      accessorKey: "CreationDate",
      header: translate("run-matching-records.columns.CreationDate"),
      size: 150,
      sortable: true,
      canFilter: true,
      canPin: false,
      truncate: true,
      filterType: "date",
      splitCellValue: "T",
    },
    {
      accessorKey: "SourceAmount",
      header: translate("run-matching-records.columns.SourceAmount"),
      size: 150,
      sortable: true,
      canPin: true,
      canFilter: true,
      filterType: "amount",
      operator: "EQ",
      processFilterValue: (value, row) => {
        return row.SourceAmount.toString();
      },
      cell: ({ row }) => {
        return (
          row.original.SourceAmount && (
            <span className="flex flex-col items-center justify-center group font-semibold">
              <span>{`${row.original.Currency} ${FormatWithCommas(
                row.original.SourceAmount
              )}`}</span>
            </span>
          )
        );
      },
    },
    {
      accessorKey: "DestinationAmount",
      header: translate("run-matching-records.columns.DestinationAmount"),
      size: 200,
      sortable: true,
      canPin: true,
      canFilter: true,
      filterType: "amount",
      operator: "EQ",
      filterCheck: (value) => {
        return value !== null;
      },
      cell: ({ row }) => {
        return (
          row.original.DestinationAmount && (
            <span className="flex flex-col items-center justify-center group font-semibold">
              <span>{`${row.original.Currency} ${FormatWithCommas(
                row.original.DestinationAmount
              )}`}</span>
            </span>
          )
        );
      },
    },
    {
      accessorKey: "Mismatches",
      header: translate("run-matching-records.columns.Mismatches"),
      size: 200,
      sortable: true,
      canPin: false,
      canFilter: false,
      cell: ({ row }) => {
        return resolveMismatchColumn(row.original.Mismatches);
      },
    },
    {
      accessorKey: "ResolutionStatusTag",
      header: translate("run-matching-records.columns.ResolutionStatus"),
      canFilter: true,
      isMultiple: true,
      sortable: true,
      size: 150,
      truncate: true,
      filterType: "checkbox",
      operator: "EQ",
      processFilterValue: (value, row) => {
        return row.ResolutionStatusTag;
      },
      processFilterKey: (value, row) => {
        return "ResolutionStatus";
      },
    },
  ];

  const filterDisplayValues = {
    trigger: [
      {
        displayName: "Manual",
        value: "Manual",
      },
      {
        displayName: "Schedule",
        value: "Schedule",
      },
    ],
    DataAcquisitionStatusTag: [
      {
        displayName: "All",
        value: null,
      },
      {
        displayName: "Active",
        value: "ACTIVE",
      },
      {
        displayName: "Disabled",
        value: "DISABLED",
      },
      {
        displayName: "On Hold",
        value: "ON_HOLD",
      },
    ],
    ResolutionStatus: getDynamicFilters("ResolutionStatusTag"),
  };

  const filters = [
    {
      mainFilters: [
        {
          key: "ResolutionStatus",
          displayName: translate(
            "run-matching-records.filters.ResolutionStatus"
          ),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("run-matching-records.filters.ResolutionStatus"),
              type: "checkboxGroup",
              key: "ResolutionStatus",
              values: getDynamicFilters("ResolutionStatusTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "Mismatches",
          displayName: translate("run-matching-records.filters.Mismatch"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("run-matching-records.filters.Mismatch"),
              type: "title",
              key: "title",
            },
            {
              name: translate("run-matching-records.filters.Mismatch"),
              type: "checkboxGroup",
              key: "MismatchTag",
              values: getDynamicFilters("MismatchTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate("run-matching-records.filters.MismatchSeverity"),
              type: "checkboxGroup",
              key: "MismatchSeverityTag",
              values: getDynamicFilters("MismatchSeverityTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "Location",
          displayName: translate("run-matching-records.filters.Location"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("run-matching-records.filters.Locations"),
              type: "title",
              key: "title",
            },
            {
              name: translate("run-matching-records.filters.Area"),
              type: "checkboxGroup",
              key: "AreaId",
              values: getDynamicFilters("AreaId"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate("run-matching-records.filters.Outlet"),
              type: "checkboxGroup",
              key: "OutletId",
              values: getDynamicFilters("OutletId"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate("run-matching-records.filters.Terminal"),
              type: "checkboxGroup",
              key: "TransactionTerminalCode",
              values: getDynamicFilters("TransactionTerminalCode"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "RecordReference",
          displayName: translate(
            "run-matching-records.filters.RecordReference"
          ),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
              />
            </svg>
          ),
          components: [
            {
              name: translate("run-matching-records.filters.RecordReference"),
              type: "searchInput",
              key: "RecordReference",
              filterType: "string",
              operator: "EQ",
            },
          ],
        },
        {
          key: "CreationDate",
          displayName: translate("run-matching-records.columns.CreationDate"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
          ),
          components: [
            {
              name: translate("run-matching-records.filters.PleaseSelectDate"),
              type: "title",
              key: "title",
            },
            {
              name: translate("run-matching-records.columns.CreationDate"),
              type: "dateInput",
              inputType: "date",
              key: "CreationDate",
              placeHolder: translate(
                "run-matching-records.columns.CreationDate"
              ),
              prefix: "",
              filterType: "date",
            },
          ],
        },
        {
          key: "amount",
          displayName: translate("run-matching-records.filters.Amount"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          ),
          components: [
            {
              name: translate("run-matching-records.columns.SourceAmount"),
              type: "numberInput",
              key: "SourceAmount",
              filterType: "amount",
              inputType: "number",
            },
            {
              name: translate("run-matching-records.columns.DestinationAmount"),
              type: "numberInput",
              key: "DestinationAmount",
              filterType: "amount",
              inputType: "number",
            },
          ],
        },
      ],
    },
  ];

  const selectedFiltersDisplay = {
    AreaId: translate("run-matching-records.filters-display.AreaId"),
    OutletId: translate("run-matching-records.filters-display.OutletId"),
    TransactionTerminalCode: translate(
      "run-matching-records.filters-display.TransactionTerminalCode"
    ),
    ReconciliationRunId: translate(
      "run-matching-records.filters-display.ReconciliationRunId"
    ),
    BusinessDay: translate("run-matching-records.filters-display.BusinessDay"),
    ResolutionStatusTag: translate(
      "run-matching-records.filters-display.ResolutionStatusTag"
    ),
    CreationDate: translate(
      "run-matching-records.filters-display.CreationDate"
    ),
    SourceAmount: translate(
      "run-matching-records.filters-display.SourceAmount"
    ),
    DestinationAmount: translate(
      "run-matching-records.filters-display.DestinationAmount"
    ),
    ResolutionStatus: translate(
      "run-matching-records.filters-display.ResolutionStatus"
    ),
    MismatchSeverityTag: translate(
      "run-matching-records.filters-display.MismatchSeverityTag"
    ),
    MismatchTag: translate("run-matching-records.filters-display.MismatchTag"),
    RecordReference: translate(
      "run-matching-records.filters-display.RecordReference"
    ),
  };

  const multipleValueColumns = columns
    .filter((column) => column.isMultiple)
    .map((column) => column.accessorKey);

  const statisticsDisplayValues = {
    runID: translate("run-matching-records.statistics-display.runID"),
    streamName: translate("run-matching-records.statistics-display.streamName"),
    businessDay: translate(
      "run-matching-records.statistics-display.businessDay"
    ),
    TotalRunRecordsCount:
      currentType === "INTRANSIT"
        ? translate("run-matching-records.statistics-display.FoundInTransit")
        : translate(
            "run-matching-records.statistics-display.FoundInExceptions"
          ),
    ResolvedRecordsCount: translate(
      "run-matching-records.statistics-display.ResolvedRecordsCount"
    ),
    PartiallyResolvedRecordsCount: translate(
      "run-matching-records.statistics-display.PartiallyResolvedRecordsCount"
    ),
    UnresolvedRecordsCount: translate(
      "run-matching-records.statistics-display.UnresolvedRecordsCount"
    ),
  };

  return myStore.loading ? (
    <div className="fixed top-0 left-0 w-screen h-dvh bg-black opacity-50 flex justify-center items-center z-50">
      <Loader />
    </div>
  ) : (
    <>
      <Observer>
        {() => {
          return (
            <CustomTable
              store={myStore}
              columnsWithoutPin={columns}
              loading={myStore.loading}
              pinCondition={(row) => {
                return false;
              }}
              filterDisplayValues={filterDisplayValues}
              selectedFiltersDisplay={selectedFiltersDisplay}
              filters={filters}
              pinningInitialState={pinningInitialState}
              setParentSelectedValues={() => {}}
              prefix="RunMatchingRecords"
              title={
                currentType === "INTRANSIT"
                  ? translate(
                      "run-matching-records.statistics-display.in-transit-title"
                    )
                  : translate(
                      "run-matching-records.statistics-display.exceptions-title"
                    )
              }
              multipleValueColumns={multipleValueColumns}
              pinnedRowHeight={62}
              maxTableHeight={400}
              showFilter={true}
              autoFillData={false}
              hideActionColumn={true}
              additionalComponent={() => {
                return (
                  <div className="flex justify-between mx-auto w-full mt-5">
                    {Object.keys(statistics).length > 0 &&
                      Object.keys(statistics).map((key, index) => {
                        return (
                          <div key={index} className="flex gap-4 items-center">
                            <div className="flex gap-2 border-gray-300 rounded p-2 w-full min-w-max">
                              <div className="flex flex-col items-start">
                                <h2 className="font-semibold text-xs mb-1 text-center">
                                  {statisticsDisplayValues[key]}
                                </h2>
                                <div className="flex justify-center">
                                  <div className="text-sm ">
                                    {statistics[key]}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              }}
              additionalComponentPosition="top"
            />
          );
        }}
      </Observer>
    </>
  );
};

export default RunMatchingRecords;
