import React, { useEffect, useState } from "react";
import CustomTable from "@components/CustomTable";
import myStore from "./store";
import { Observer } from "mobx-react";
import { SVG } from "@helpers/svg";

import {
  getReconciliationRunLog,
  getCutoffSettings,
  fetchDynamicFilters,
  transformData,
  RollbackReconciliationRun,
  GetSFMDisplay,
  GetReconciliationFile,
  FormatWithCommas,
  resolveStatusColumn,
  addToDownloadCenter,
  extractFileName,
  extractFileSize
} from "@helpers/helpers";
import InfoModalStore from "@stores/InfoModalStore";
import DownloadModalStore from "@stores/DownloadModalStore";
import BusinessDayComponent from "@components/BusinessDayComponent";
import SfmModalStore from "@stores/SfmModalStore";

import LegendComponent from "@components/LegendComponent";

import { useTranslation } from "react-i18next";

const ReconciliationRunsComponent = () => {
  const { t: translate, i18n } = useTranslation();
  // const statusColors = {
  //   INPROGRESS: "bg-gray-400",
  //   COMPLETED: "bg-green-400",
  //   FAILED: "bg-red-400",
  //   ABORTED: "bg-red-900",
  //   ROLLED_BACK: "bg-yellow-400",
  //   ROLLING_BACK: "bg-ORANGE-400",
  // };

  const [dynamicFilters, setDynamicFilters] = useState([]);
  const [cutOffSettings, setCutOffSettings] = useState({});
  useEffect(() => {
    fetchDynamicFilters("ReconciliationRunFilters", (newFilters) => {
      setDynamicFilters(newFilters);
    });
    getCutoffSettings((cutOffSettings) => {
      setCutOffSettings(cutOffSettings.CutoffSettings);
    });
  }, []);

  const getDynamicFilters = (key, onlyChildren = false) => {
    return transformData(
      (!!dynamicFilters &&
        dynamicFilters.length > 0 &&
        dynamicFilters.find((filter) => filter.ColumnName === key)?.Values) ||
        [],
      onlyChildren
    );
  };

  const pinningInitialState = ["pin", "ReconciliationRunStatusName"];

  const legendData = [
    {
      color: "#742a2a",
      text: translate("reconciliation-runs.legend.aborted"),
      tag: "ABORTED",
    },
    {
      color: "#f6e05e",
      text: translate("reconciliation-runs.legend.rolledBack"),
      tag: "ROLLED_BACK",
    },
    {
      color: "#f6ad55",
      text: translate("reconciliation-runs.legend.rollingBack"),
      tag: "ROLLING_BACK",
    },
  ];

  const columns = [
    {
      accessorKey: "ReconciliationStreamName",
      header: translate("reconciliation-runs.columns.ReconciliationStreamName"),
      canFilter: true,
      isMultiple: false,
      sortable: true,
      size: 230,
      truncate: true,
      processFilterValue: (value, row) => {
        return row.ReconciliationStreamTag;
      },
      processFilterKey: (value, row) => {
        return "ReconciliationStreamTag";
      },
      filterType: "checkbox",
      operator: "EQ",
    },
    {
      accessorKey: "Trigger",
      header: translate("reconciliation-runs.columns.Trigger"),
      size: 99,
      canFilter: false,
      isMultiple: false,
      sortable: true,
      truncate: false,
      hideCopy: true,
      cell: ({ row }) => {
        return (
          <span
            className={`rounded-full flex items-end justify-center gap-1 w-full font-normal text-sm border border-r-10 border-transparent `}
          >
            {row.original.IsScheduleTrigger && (
              <span>{translate("reconciliation-runs.columns.Scheduled")}</span>
            )}
            {row.original.IsManualTrigger && (
              <span>{translate("reconciliation-runs.columns.Manual")}</span>
            )}
          </span>
        );
      },
    },
    {
      accessorKey: "BusinessDay",
      header: translate("reconciliation-runs.columns.BusinessDay"),
      size: 150,
      sortable: true,
      canPin: false,
      canFilter: true,
      filterType: "date",
    },
    {
      accessorKey: "StartDate",
      header: translate("reconciliation-runs.columns.StartDate"),
      size: 125,
      sortable: true,
      truncate: true,
      canFilter: true,
      filterType: "date",
      splitCellValue: "T",
    },
    {
      accessorKey: "EndDate",
      header: translate("reconciliation-runs.columns.EndDate"),
      size: 125,
      sortable: true,
      truncate: true,
      canFilter: true,
      filterType: "date",
      splitCellValue: "T",
    },
    {
      accessorKey: "ReconciliationRunStatusName",
      header: translate("reconciliation-runs.columns.RunStatus"),
      headerHelper: () => {
        return <LegendComponent data={legendData} />;
      },
      size: 50,
      sortable: true,
      canFilter: true,
      hideHeader: false,
      hideCopy: true,
      isMultiple: true,
      processFilterValue: (value, row) => {
        return row.ReconciliationRunStatusTag;
      },
      processFilterKey: (value, row) => {
        return "ReconciliationRunStatusTag";
      },
      filterType: "checkbox",
      operator: "EQ",
      cell: ({ row }) => {
        return resolveStatusColumn(
          row.original.ReconciliationRunStatusName,
          row.original.ReconciliationRunStatusTag
        );
      },
    },
    {
      accessorKey: "TotalMatchingRecords",
      header: translate("reconciliation-runs.columns.MatchingRecords"),
      size: 200,
      canFilter: false,
      truncate: true,
      // displayInPopup: true,
      showThousandSeperator: true,
      popupCellDisplay: (value) => {
        return (
          <div className="relative text-purple-400 font-semibold">
            {!!value && value}
          </div>
        );
      },
      popupContent: (row) => {
        const value = row.TotalMatchingRecords;
        return (
          <div className="relative ">
            <div className="flex flex-col min-w-[264px] transition-all bg-primary-dark text-[#777f9e] text-14 rounded-lg px-[2px] py-[8px] mb-3 opacity-100">
              <div className="flex justify-between">
                <span className="font-semibold">
                  {translate(
                    "reconciliation-runs.columns.TotalMatchingRecords"
                  )}
                </span>
                <span className="font-semibold">{FormatWithCommas(value)}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "TotalReconciledRecords",
      header: translate("reconciliation-runs.columns.ReconciledRecords"),
      size: 200,
      canFilter: false,
      truncate: true,
      // displayInPopup: true,
      showThousandSeperator: true,
      popupCellDisplay: (value) => {
        return (
          <div className="relative text-purple-400 font-semibold">
            {!!value && value}
          </div>
        );
      },
      popupContent: (row) => {
        const value = row.TotalReconciledRecords;
        return (
          <div className="relative ">
            <div className="flex flex-col min-w-[264px] transition-all bg-primary-dark text-[#777f9e] text-14 rounded-lg px-[2px] py-[8px] mb-3 opacity-100">
              <div className="flex justify-between">
                <span className="font-semibold">
                  {translate(
                    "reconciliation-runs.columns.TotalReconciledRecords"
                  )}
                </span>
                <span className="font-semibold">{FormatWithCommas(value)}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "TotalInTransitRecords",
      header: translate("reconciliation-runs.columns.InTransitRecords"),
      size: 200,
      canFilter: false,
      truncate: true,
      processFilterValue: (value, row) => {
        return row.ReconciliationRunId.toString();
      },
      processFilterKey: (value, row) => {
        return "RunID";
      },
      showThousandSeperator: true,
      cellAction: (value, context) => {
        window.location.href = `/run-matching-records?run_id=${context.row.original.ReconciliationRunId}&stream_name=${context.row.original.ReconciliationStreamName}&type=InTransit&business_day=${context.row.original.BusinessDay}&business_day_instance=${context.row.original.BDInstance}`;
      },
      cellActionCheck: (value, context) =>
        value > 0 &&
        context.row.original.ReconciliationRunStatusName === "Completed",
    },
    {
      accessorKey: "TotalExceptionRecords",
      header: translate("reconciliation-runs.columns.ExceptionRecords"),
      size: 200,
      canFilter: false,
      truncate: true,
      processFilterValue: (value, row) => {
        return row.ReconciliationRunId;
      },
      processFilterKey: (value, row) => {
        return "RunID";
      },
      cellAction: (value, context) => {
        window.location.href = `/run-matching-records?run_id=${context.row.original.ReconciliationRunId}&stream_name=${context.row.original.ReconciliationStreamName}&type=Exception&business_day=${context.row.original.BusinessDay}&business_day_instance=${context.row.original.BDInstance}`;
      },
      cellActionCheck: (value, context) =>
        value > 0 &&
        context.row.original.ReconciliationRunStatusName === "Completed",
      showThousandSeperator: true,
    },
  ];

  const filterDisplayValues = {
    trigger: [
      {
        displayName: translate("filters-display.Manual"),
        value: "Manual",
        selectable: true,
      },
      {
        displayName: translate("filters-display.Schedule"),
        value: "Schedule",
        selectable: true,
      },
    ],
  };

  const filters = [
    {
      mainFilters: [
        {
          key: "ReconciliationStreamName",
          displayName: translate("reconciliation-runs.filters.stream"),
          icon: () => SVG({ size: 4, icon: "stream" }),
          components: [
            {
              name: translate("reconciliation-runs.filters.reconciliations"),
              type: "title",
              key: "title",
            },
            {
              name: translate(
                "reconciliation-runs.filters.reconciliationStreamStatus"
              ),
              type: "checkboxGroup",
              key: "ReconciliationRunStatusTag",
              values: getDynamicFilters("ReconciliationRunStatusTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
            {
              name: translate(
                "reconciliation-runs.filters.reconciliationStreamName"
              ),
              type: "checkboxGroup",
              key: "ReconciliationStreamTag",
              values: getDynamicFilters("ReconciliationStreamTag"),
              filterType: "checkbox",
              operator: "EQ",
            },
          ],
        },
        {
          key: "NextReconciliationRun",
          displayName: translate("reconciliation-runs.filters.dates"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
          ),
          components: [
            {
              name: translate("reconciliation-runs.filters.selectDate"),
              type: "title",
              key: "title",
            },
            {
              name: translate("reconciliation-runs.filters.startDate"),
              type: "dateInput",
              inputType: "date",
              key: "StartDate",
              placeHolder: translate("reconciliation-runs.filters.startDate"),
              prefix: "",
              filterType: "date",
              dynamicMaxDate: (selectedValues) => { return selectedValues?.EndDate?.value},
            },
            {
              name: translate("reconciliation-runs.filters.endDate"),
              type: "dateInput",
              inputType: "date",
              key: "EndDate",
              placeHolder: translate("reconciliation-runs.filters.endDate"),
              prefix: "",
              filterType: "date",
              dynamicMinDate: (selectedValues) => { return selectedValues?.StartDate?.value},
            },
            {
              name: translate("reconciliation-runs.filters.businessDay"),
              type: "dateInput",
              inputType: "date",
              key: "BusinessDay",
              placeHolder: translate("reconciliation-runs.filters.businessDay"),
              prefix: "",
              filterType: "date",
            },
          ],
        },
        {
          key: "ReconciliationRunId",
          displayName: translate("reconciliation-runs.filters.runId"),
          icon: () => (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
              />
            </svg>
          ),
          components: [
            {
              name: translate("reconciliation-runs.filters.runId"),
              type: "title",
              key: "title",
              description: translate(
                "reconciliation-runs.filters.runIdDescription"
              ),
            },
            {
              name: translate("reconciliation-runs.filters.runId"),
              type: "numberInput",
              key: "RunId",
              placeHolder: translate("shared.filterRunID"),
              decimalPoint: 0,
              step: 1,
              operator: "EQ",
              filterType: "string",
              hasDecimal:false
            },
          ],
        },
      ],
    },
  ];

  const selectedFiltersDisplay = {
    direction: translate("filters-display.Direction"),
    ReconciliationStreamTag: translate("filters-display.StreamName"),
    TotalInTransitRecords: translate("filters-display.InTransitRecordID"),
    TotalExceptionRecords: translate("filters-display.ExceptionRecordID"),
    TotalMatchingRecords: translate("filters-display.MatchingRecordID"),
    TotalReconciledRecords: translate("filters-display.ReconciledRecordID"),
    NextReconciliationRun: translate("filters-display.NextRun"),
    StartDate: translate("filters-display.StartDate"),
    EndDate: translate("filters-display.EndDate"),
    ScheduleDate: translate("filters-display.ScheduleDate"),
    contains: translate("filters-display.Contains"),
    startWith: translate("filters-display.StartsWith"),
    endWith: translate("filters-display.EndsWith"),
    IsScheduled: translate("filters-display.Scheduled"),
    ReconciliationRunStatusTag: translate("reconciliation-runs.filters.reconciliationStreamStatus"),
    Trigger: translate("filters-display.Trigger"),
    RunId: translate("filters-display.RunID"),
    BusinessDay: translate("filters-display.BusinessDay"),
  };

  const actions = {
    VIEW_DETAILS: {
      actionTypeTag: "VIEW_DETAILS",
      name: translate("reconciliation-runs.actions.VIEW_DETAILS"),
      actionFN: (row, ids, selectedReason, reasonDescription) => {
        getReconciliationRunLog({
          ReconciliationRunId: row.original.ReconciliationRunId,
          callback: (data) => {
            if (data.ReconciliationRunLog) {
              InfoModalStore.open({
                type: "Reconciliation_Run_Info",
                title: translate(
                  "reconciliation-runs.titles.RECONCILIATION_RUN_INFO"
                ),
                data: data.ReconciliationRunLog,
                show: true,
              });
            } else {
              const sfmData = GetSFMDisplay(data.SFM);
              SfmModalStore.open({
                DetailedMessage: translate("shared.no-details-message"),
                Message: "SUCCESS",
                SfmTag: "SFM_SUCCESS",
                Type: 1,
                ShowMessage: true,
              });
            }
          },
          store: myStore,
        });
      },
      confirmation: false,
    },
    DOWNLOAD_MATCHING_RESULTS: {
      actionTypeTag: "DOWNLOAD_MATCHING_RESULTS",
      name: translate("reconciliation-runs.actions.DOWNLOAD_MATCHING_RESULTS"),
      actionFN: (row) => {
        myStore.loading = true;
        GetReconciliationFile(
          "Reconciliation",
          "RECONCILIATION_STREAM_MATCHING_RESULTS",
          "ReconciliationRunId",
          row.original.ReconciliationRunId.toString(),
          (data) => {
            if (data.Files[0]) {
              const newPendingID = addToDownloadCenter({
                href: data.Files[0],
                title: extractFileName(data.Files[0]),
                size: extractFileSize(data.Files[0]),
                activityTag: "Matching Data",
                done: true,
              });
              window.open(data.Files[0], "_blank");
              DownloadModalStore.open(500);
              DownloadModalStore.highlight(500, newPendingID);
            } else {
              const sfmData = GetSFMDisplay(data.SFM);
              SfmModalStore.open(sfmData);
            }
          },
          myStore,
          i18n.language
        );
      },
      confirmationMessage: translate(
        "reconciliation-runs.confirmation-messages.DOWNLOAD_MATCHING_RESULTS"
      ),
      confirmation: true,
    },
    DOWNLOAD_ROLLBACK_RESULTS: {
      actionTypeTag: "DOWNLOAD_ROLLBACK_RESULTS",
      name: translate("reconciliation-runs.actions.DOWNLOAD_ROLLBACK_RESULTS"),
      actionFN: (row) => {
        myStore.loading = true;
        GetReconciliationFile(
          "Reconciliation",
          "RECONCILIATION_STREAM_ROLLBACK_RESULTS",
          "ReconciliationRunId",
          row.original.ReconciliationRunId.toString(),
          (data) => {
            if (data.Files[0]) {
              const newPendingID = addToDownloadCenter({
                href: data.Files[0],
                title: extractFileName(data.Files[0]),
                size: extractFileSize(data.Files[0]),
                activityTag: "RollBack Data",
                done: true,
              });
              window.open(data.Files[0], "_blank");
              DownloadModalStore.open(500);
              DownloadModalStore.highlight(500, newPendingID);
            } else {
              const sfmData = GetSFMDisplay(data.SFM);
              SfmModalStore.open(sfmData);
            }
          },
          myStore,
          i18n.language
        );
      },
      confirmationMessage: translate(
        "reconciliation-runs.confirmation-messages.DOWNLOAD_ROLLBACK_RESULTS"
      ),
      confirmation: true,
    },
    DOWNLOAD_CORRECTION_LOG: {
      actionTypeTag: "DOWNLOAD_CORRECTION_LOG",
      name: translate("reconciliation-runs.actions.DOWNLOAD_CORRECTION_LOG"),
      actionFN: (row) => {
        myStore.loading = true;
        GetReconciliationFile(
          "ControlRoom",
          "RECONCILIATION_RUN_CORRECTION_LOG",
          "ReconciliationRunId",
          row.original.ReconciliationRunId.toString(),
          (data) => {
            if (data.Files[0]) {
              const newPendingID = addToDownloadCenter({
                href: data.Files[0],
                title: extractFileName(data.Files[0]),
                size: extractFileSize(data.Files[0]),
                activityTag: "Correction Log",
                done: true,
              });
              window.open(data.Files[0], "_blank");
              DownloadModalStore.open(500);
              DownloadModalStore.highlight(500, newPendingID);
            } else {
              const sfmData = GetSFMDisplay(data.SFM);
              SfmModalStore.open(sfmData);
            }
          },
          myStore,
          i18n.language
        );
      },
      confirmationMessage: translate(
        "reconciliation-runs.confirmation-messages.DOWNLOAD_CORRECTION_LOG"
      ),
      confirmation: true,
    },
    ROLLBACK: {
      actionTypeTag: "ROLLBACK",
      name: translate("reconciliation-runs.actions.ROLLBACK"),
      actionFN: (row) => {
        RollbackReconciliationRun(
          row.original.ReconciliationRunId,
          (data) => {
            const sfmData = GetSFMDisplay(data.SFM);
            SfmModalStore.open(sfmData, () =>{
              window.location.reload();
            });
          },
          myStore
        );
      },
      confirmationMessage: translate(
        "reconciliation-runs.confirmation-messages.ROLLBACK"
      ),
      confirmation: true,
    },
    VIEW_CORRECTION_LOG: {
      actionTypeTag: "VIEW_CORRECTION_LOG",
      name: translate("reconciliation-runs.actions.VIEW_CORRECTION_LOG"),
      actionFN: (row) => {
        myStore.loading = true;
        GetReconciliationFile(
          "ControlRoom",
          "RECONCILIATION_RUN_CORRECTION_LOG",
          "ReconciliationRunId",
          row.original.ReconciliationRunId.toString(),
          (data) => {
            if (data.Files[0]) {
              const newPendingID = addToDownloadCenter({
                href: data.Files[0],
                title: extractFileName(data.Files[0]),
                size: extractFileSize(data.Files[0]),
                activityTag: "Correction Log",
                done: true,
              });
              window.open(data.Files[0], "_blank");
              DownloadModalStore.open(500);
              DownloadModalStore.highlight(500, newPendingID);
            } else {
              const sfmData = GetSFMDisplay(data.SFM);
              SfmModalStore.open(sfmData);
            }
          },
          myStore,
          i18n.language
        );
      },
      confirmationMessage: translate(
        "reconciliation-runs.confirmation-messages.VIEW_CORRECTION_LOG"
      ),
      confirmation: true,
    },
  };

  return (
    <Observer>
      {() => {
        return (
          <>
            <CustomTable
              language={i18n.language}
              maxTableHeight={"calc(100dvh - 400px)"}
              store={myStore}
              columnsWithoutPin={columns}
              loading={myStore.loading}
              pinCondition={(row) => {
                return row.IsScheduled;
              }}
              filterDisplayValues={filterDisplayValues}
              selectedFiltersDisplay={selectedFiltersDisplay}
              filters={filters}
              pinningInitialState={pinningInitialState}
              setParentSelectedValues={() => {}}
              actions={actions}
              prefix="ReconciliationRuns"
              title={translate("reconciliation-runs.title")}
              pinnedRowHeight={57}
              additionalComponent={() => (
                <BusinessDayComponent
                  data={cutOffSettings}
                  reloadCutoffData={() => {
                    getCutoffSettings((cutOffSettings) => {
                      setCutOffSettings(cutOffSettings.CutoffSettings);
                    });
                  }}
                />
              )}
            />
            {/* {!myStore.loading && (
              <div className="container mx-auto">
                <div className="mx-auto w-[90%] pt-5">
                  <LegendComponent data={legendData} inline />
                </div>
              </div>
            )} */}
          </>
        );
      }}
    </Observer>
  );
};

export default ReconciliationRunsComponent;
