import React, { useEffect, useState } from "react";
import {
  getAttemptDetails,
  GetSFMDisplay,
  DIGIT_DECIMAL_POINT,
  resolveMismatchColumn,
  FormatWithCommas,
} from "@helpers/helpers";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomTable from "@components/CustomTable";
import Loader from "@components/LoaderComponent/loader";
import TailwindDropdown from "@components/TailwindDropdown";
import { CopyToCliboard } from "@components/CustomTable/helpers";
import clsx from "clsx";
import SfmModalStore from "@stores/SfmModalStore";

const AttemptDetails = ({ sentActivityTag, id: passedId, from }) => {
  const [attemptDetails, setAttemptDetails] = useState({});
  const [selectedAttempt, setSelectedAttempt] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [recordId, setRecordId] = useState(null);
  const [showSection, setShowSection] = useState(["basicInfo"]);
  const location = useLocation();
  const { t: translate, i18n } = useTranslation();

  const statusColors = {
    CRITICAL: "bg-red-400",
    LOW: "bg-yellow-400",
  };
  const textColors = {
    CRITICAL: "text-red-400",
    LOW: "text-yellow-400",
  };
  useEffect(() => {
    // New way to extract the ID using URLSearchParams
    const queryParams = new URLSearchParams(location.search);
    const id = passedId || queryParams.get("id"); // Assuming the ID is passed as a query parameter named "id"

    // Use the extracted ID as needed, for example:
    getAttemptDetails(id, sentActivityTag, (attemptDetailsInfo) => {
      if (attemptDetailsInfo?.Attempts) {
        setAttemptDetails(attemptDetailsInfo);
        const lastAttemptId = attemptDetailsInfo.Attempts.length - 1;
        setSelectedAttempt(attemptDetailsInfo.Attempts[lastAttemptId]);
        setRecordId(id);
        if (attemptDetailsInfo.Attempts[lastAttemptId]["CorrectiveAction"]) {
          setShowSection([...showSection, "correctiveAction"]);
        }
        setIsLoading(false);
      } else {
        const sfmData = GetSFMDisplay(attemptDetailsInfo.SFM);
        const redirection = {
          exception: "/exception-records",
          transit: "/in-transit-list",
        };
        SfmModalStore.open(sfmData, () => {
          window.location.href = redirection[from];
        });
      }
    });
  }, [location, sentActivityTag, passedId]);

  const pinningInitialState = ["pin", "ReconciliationRunStatusName"];
  const columns = [
    {
      accessorKey: "ReconciliationStreamName",
      header: translate("attempt-details.columns.ReconciliationStreamName"),
      size: 230,
      truncate: true,
    },
    {
      accessorKey: "MatchingSourceName",
      header: translate("attempt-details.columns.DataSourceName"),
      size: 150,
      cell: ({ row }) => {
        return (
          <span className="flex flex-col items-center justify-center group font-semibold">
            <span>{row.original.DataAcquisitionName}</span>
          </span>
        );
      },
    },
    {
      accessorKey: "AreaName",
      header: translate("attempt-details.columns.AreaName"),
      size: 150,
    },
    {
      accessorKey: "OutletName",
      header: translate("attempt-details.columns.OutletName"),
      size: 150,
    },
    {
      accessorKey: "TransactionTerminalCode",
      header: translate("attempt-details.columns.TransactionTerminalCode"),
      size: 150,
    },
    {
      accessorKey: "RecordReference",
      header: translate("attempt-details.columns.RecordReference"),
      size: 150,
    },
    {
      accessorKey: "RecordDate",
      header: translate("attempt-details.columns.RecordDate"),
      size: 180,
    },
    {
      accessorKey: "RecordType",
      header: translate("attempt-details.columns.RecordType"),
      size: 150,
    },
    {
      accessorKey: "Attributes",
      header: translate("attempt-details.columns.Attributes"),
      size: 150,
      displayInPopup: true,
      popupCellDisplay: (value) => {
        return (
          <div className="relative text-purple-400 font-semibold">
            ({value.length})
          </div>
        );
      },
      popupContent: (row) => {
        const value = row.Attributes;
        return (
          <div className="relative ">
            <div className="flex flex-col min-w-[400px] transition-all bg-primary-dark text-[#777f9e] text-14 rounded-lg px-[2px] py-[8px] mb-3 opacity-100">
              {value &&
                value.map((attr, index) => (
                  <div
                    className="flex flex-row justify-between gap-2 mb-[2px]"
                    key={index}
                  >
                    <span className="py-[2px] whitespace-nowrap text-xs font-semibold">
                      {attr.Key}
                    </span>
                    <span className="relative py-[2px] flex gap-2 items-center px-[8px] whitespace-nowrap ltr:text-right rtl:text-left text-sm">
                      {attr.Value}
                      <div className=" absolute left-0 w-full justify-center flex opacity-0 hover:opacity-100 bg-gray-300 bg-opacity-50 rounded">
                        <CopyToCliboard size="5" value={attr.Value} />
                      </div>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "PaymentMethod",
      header: translate("attempt-details.columns.PaymentMethod"),
      size: 180,
    },
    {
      accessorKey: "Amount",
      header: translate("attempt-details.columns.Amount"),
      size: 130,
      cell: ({ row }) => {
        return (
          <span className="flex flex-col items-center justify-center group font-semibold">
            <span>{`${row.original.Currency} ${FormatWithCommas(
              row.original.Amount
            )}`}</span>
          </span>
        );
      },
    },
    {
      accessorKey: "InTransitDeadline",
      header: translate("attempt-details.columns.DeadlineDate"),
      size: 130,
    },
  ];

  const noData = () => {
    return (
      <div className="text-center w-full flex flex-col items-center gap-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3"
          />
        </svg>
        <span>{translate("shared.no-data")}</span>
      </div>
    );
  };

  const renderSection = (
    identifier,
    title,
    data,
    allowedKeys,
    displayValues,
    customValues
  ) => {
    const expanded = showSection.includes(identifier);
    return (
      <div className="section mt-10 pb-10 px-5 border-b-2 border-gray-200">
        <div
          className={`title flex gap-2 items-center cursor-pointer font-bold text-lg`}
          onClick={() =>
            setShowSection([
              ...(expanded
                ? showSection.filter((item) => item !== identifier)
                : [...showSection, identifier]),
            ])
          }
        >
          {title}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={clsx(
              expanded && "rotate-180",
              `size-5 transition-all duration-500`
            )}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 15.75 7.5-7.5 7.5 7.5"
            />
          </svg>
        </div>
        <div
          className={clsx(
            `content px-5 grid grid-rows-[0fr] transition-all ease-in-out duration-500`,
            expanded ? "  opacity-100 grid-rows-[1fr] mt-10" : "opacity-0 mt-0"
          )}
        >
          <div
            className={clsx(
              `overflow-hidden grid`,
              data && "grid-cols-3 gap-6"
            )}
          >
            {!!data
              ? Object.keys(data).map(
                  (key, index) =>
                    allowedKeys.includes(key) && (
                      <div
                        key={index}
                        className="info flex flex-col gap-3 items-start"
                      >
                        <div className="key font-semibold">
                          {displayValues[key] || key}
                        </div>
                        <div className="value">
                          {!!customValues[key] ? customValues[key] : data[key]}
                        </div>
                      </div>
                    )
                )
              : noData()}
          </div>
        </div>
      </div>
    );
  };

  const renderTables = () => {
    const sourceData = attemptDetails.PromotedRecords.filter((record) =>
      selectedAttempt.SourcePromotedRecordIds.includes(record.PromotedRecordId)
    );
    const destinationData = attemptDetails.PromotedRecords.filter((record) =>
      selectedAttempt.DestinationPromotedRecordIds.includes(
        record.PromotedRecordId
      )
    );

    const destinationDataAmount = destinationData.reduce((acc, record) => {
      return acc + record.Amount;
    }, 0);
    const sourceDataAmount = sourceData.reduce((acc, record) => {
      return acc + record.Amount;
    }, 0);
    return (
      <div className="p-5 w-full flex flex-col gap-2">
        {destinationData.length > 0 && (
          <CustomTable
            language={i18n.language}
            externalData={destinationData}
            columnsWithoutPin={columns}
            loading={false}
            pinCondition={(row) => {
              return false;
            }}
            pinningInitialState={pinningInitialState}
            setParentSelectedValues={() => {}}
            prefix="DestinationRecords"
            title={`${destinationData.length} ${translate(
              "attempt-details.destination-records"
            )} ${
              destinationData.length > 1 ||
              destinationData.length === 0 ||
              !destinationData
                ? "s"
                : ""
            }`}
            secondaryTitle={
              destinationDataAmount
                ? `${
                    attemptDetails.PromotedRecords[0].Currency
                  } ${FormatWithCommas(
                    parseFloat(destinationDataAmount)
                  )}`
                : ""
            }
            pinnedRowHeight={62}
            maxTableHeight={400}
            showFilter={false}
            minimalTable={true}
            hideActionColumn={true}
            noData={noData}
            isExpandable={true}
          />
        )}
        {sourceData.length > 0 && (
          <CustomTable
            language={i18n.language}
            externalData={sourceData}
            columnsWithoutPin={columns}
            loading={false}
            pinCondition={(row) => {
              return false;
            }}
            pinningInitialState={pinningInitialState}
            setParentSelectedValues={() => {}}
            prefix="SourceRecords"
            title={`${sourceData.length} ${translate(
              "attempt-details.source-records"
            )} ${
              sourceData.length > 1 || sourceData.length === 0 || !sourceData
                ? "s"
                : ""
            }`}
            secondaryTitle={
              sourceDataAmount
                ? `${
                    attemptDetails.PromotedRecords[0].Currency
                  } ${FormatWithCommas(
                    parseFloat(sourceDataAmount)
                  )}`
                : ""
            }
            pinnedRowHeight={62}
            maxTableHeight={400}
            showFilter={false}
            minimalTable={true}
            hideActionColumn={true}
            noData={noData}
            isExpandable={true}
          />
        )}
      </div>
    );
  };
  const attempts = attemptDetails?.Attempts;
  return isLoading ? (
    <div className="fixed top-0 left-0 w-screen h-dvh bg-black opacity-50 flex justify-center items-center z-50">
      <Loader />
    </div>
  ) : (
    <div>
      <div className="text-3xl py-5 px-4 font-bold w-full text-center flex">
        {from === "exception"
          ? translate("attempt-details.exceptionTitle")
          : translate("attempt-details.inTransitTitle")}
      </div>
      {selectedAttempt && (
        <div className="border-2 border-gray-200 rounded mr-5 text-black">
          {attempts.length > 1 && (
            <div className="tabs px-4">
              <TailwindDropdown
                data={attempts?.map((item, index) => {
                  return {
                    key: index,
                    value: `${translate("attempt-details.attempt")} ${
                      index + 1
                    } (${item.BusinessDay} ${item.BDInstance})`,
                  };
                })}
                selectedValue={attempts.indexOf(selectedAttempt)}
                setActiveTab={(index) =>
                  setSelectedAttempt(attemptDetails.Attempts[index])
                }
              />
            </div>
          )}
          <span className="text-3xl pt-10 px-4 font-bold w-full text-center flex">
            {translate("attempt-details.attempt")}{" "}
            {attemptDetails.Attempts.indexOf(selectedAttempt) + 1}
          </span>
          {renderSection(
            "basicInfo",
            translate("attempt-details.section.BasicInfo"),
            { AttemptType: "", ...selectedAttempt },
            [
              "AttemptType",
              "BusinessDay",
              "MatchingResultName",
              "RecordMatchingKeys",
              "CreationDate",
              "Mismatches",
            ],
            {
              AttemptType: translate("attempt-details.section.AttemptType"),
              BusinessDay: translate("attempt-details.section.BusinessDay"),
              MatchingResultName: translate(
                "attempt-details.section.MatchingResultName"
              ),
              RecordMatchingKeys: translate(
                "attempt-details.section.RecordMatchingKeys"
              ),
              CreationDate: translate("attempt-details.section.CreationDate"),
              Mismatches: translate("attempt-details.section.Mismatches"),
            },
            {
              AttemptType: (
                <div className="flex flex-col gap-2">
                  <span className="">
                    {selectedAttempt.MatchingSource.MatchingSourceName} (
                    {translate("shared.runId")}: {selectedAttempt.RunId})
                  </span>
                </div>
              ),
              BusinessDay: `${selectedAttempt.BusinessDay} ${selectedAttempt.BDInstance}`,
              RecordMatchingKeys: selectedAttempt.RecordMatchingKeys.join(),
              Mismatches: resolveMismatchColumn(selectedAttempt.Mismatches),
            }
          )}
          {selectedAttempt["CorrectiveAction"] &&
            renderSection(
              "correctiveAction",
              "Corrective Action",
              selectedAttempt["CorrectiveAction"],
              [
                "ActionTypeName",
                "ActionTypeDescription",
                "ActionDate",
                "ActionReasonName",
                "ActionReasonDescription",
                "ResultantClearedMatchingRecordCount",
              ],
              {
                ActionTypeName: "Action Type",
                ActionTypeDescription: "Action Description",
                ActionDate: "Action Date",
                ActionReasonName: "Action Reason",
                ActionReasonDescription: "Reason Description",
                ResultantClearedMatchingRecordCount: "Cleared Matching Records",
              },
              {}
            )}
          <span
            className={`title flex gap-2 items-center font-semibold text-gray-500 text-md px-5 pt-10`}
          >
            {translate("attempt-details.matched-records.title")}
          </span>
          {renderTables()}
        </div>
      )}
    </div>
  );
};
export default AttemptDetails;
